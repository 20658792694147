<template>
  <section class="kyukai">
    <AppHeader :header-text="headerText" />
    <v-content>
      <HomeTitle :title-text="titleText" />
      <KyukaiMain/>
    </v-content>
    <AppFooter/>
  </section>
</template>

<script>
import HomeTitle from '@/components/HomeTitle.vue';
import KyukaiMain from '@/components/KyukaiMain.vue';
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  name: 'Kyukai',
  components: {
    HomeTitle,
    KyukaiMain,
    AppHeader,
    AppFooter,
  },

  data: () => ({
    titleText: '休会のお手続き',
    headerText: '休会申請',
  }),

}
</script>

<style lang="scss" scoped>
.kyukai {
    padding-bottom: 50px;
}
</style>