import { render, staticRenderFns } from "./Kyukai.vue?vue&type=template&id=c5f4ee68&scoped=true&"
import script from "./Kyukai.vue?vue&type=script&lang=js&"
export * from "./Kyukai.vue?vue&type=script&lang=js&"
import style0 from "./Kyukai.vue?vue&type=style&index=0&id=c5f4ee68&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5f4ee68",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContent } from 'vuetify/lib/components/VContent';
installComponents(component, {VContent})
