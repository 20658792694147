<template>
    <section class="kyukai-main">
        <v-container class="fill-height" fluid>
            <v-row class="kyukai-main__contents">
                <v-col cols="12" sm="8" md="4" class="kyukai-main__contents-text">

                    <v-card flat color="transparent">
                        <v-card-text>
                            <v-form ref="form">
                                <v-text-field class="memberid" v-model="memberid" :rules="memberidRules" prepend-inner-icon="mdi-account-circle" label="会員番号" required @change="getname" clearable @click:clear="onClearName"></v-text-field>
                                
                                <!-- 生年月日 -->
                                <v-menu ref="birthdaymenu" v-model="birthdaymenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field  class="birthday" v-model="birthday" :rules="birthdayRules" label="生年月日" prepend-inner-icon="event" readonly v-bind="attrs" v-on="on" clearable @click:clear="onClearName"></v-text-field>
                                    </template>
                                    <v-date-picker ref="birthdaypicker" locale="jp-ja" v-model="birthday" 
                                                    :day-format="birthday => new Date(birthday).getDate()" 
                                                    :max="date.toISOString().substr(0, 10)" 
                                                    min="1920-01-01" @change="birthdaysave">
                                    </v-date-picker>
                                </v-menu>

                                <v-text-field class="name" v-model="name" prepend-inner-icon="mdi-account" label="お名前" readonly></v-text-field>
                                <v-text-field class="mail" v-model="mail" :rules="mailRules" prepend-inner-icon="mdi-email" label="メールアドレス" required clearable></v-text-field>
                                
                                <!-- 休会予定日 -->
                                <v-menu ref="stopdaymenu" v-model="stopdaymenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="stopday" v-model="stopday" :rules="stopdayRules" label="休会予定月" prepend-inner-icon="event" readonly v-bind="attrs" v-on="on" clearable @click:clear="onClearClicked"></v-text-field>
                                    </template>
                                    <v-date-picker ref="stopdaypicker" locale="jp-ja" v-model="stopday" type="month" 
                                        :day-format="stopday => new Date(stopday).getDate()" 
                                        :max="stopdateMax.toISOString().substr(0, 7)"
                                        :min="stopdateMin.toISOString().substr(0, 7)" @change="stopdaysave">
                                    </v-date-picker>
                                </v-menu>

                                <!-- 再開予定日 -->
                                <v-menu ref="restratdaymenu" v-model="restratdaymenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :disabled="enabled" class="restratday" v-model="restratday" :rules="restratdayRules" label="再開予定月" prepend-inner-icon="event" readonly v-bind="attrs" v-on="on" clearable></v-text-field>
                                    </template>
                                    <v-date-picker ref="restratdaypicker" locale="jp-ja" v-model="restratday" type="month" 
                                        :day-format="restratday => new Date(restratday).getDate()" 
                                        :max="restartdateMax.toISOString().substr(0, 7)" 
                                        :min="restartdateMin.toISOString().substr(0, 7)" @change="restratdaysave" >
                                    </v-date-picker>
                                </v-menu>
                            </v-form>
                        </v-card-text>
                    </v-card>

                </v-col>
            </v-row>
            <KyukaiCheckbox/>

            <v-row class="kyukai-main__contents">
                <v-col cols="12" sm="8" md="4" class="kyukai-main__contents-text">
                    <v-card flat color="transparent">
                        <v-card-text>
                            <v-form>
                               <v-textarea class="biko" v-model="biko" autocomplete="biko" label="備考"></v-textarea>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
                
            <v-row class="button">
                <v-col>
                    <v-btn large color="primary" min-width="300" @click="submit">
                        休会申込
                    </v-btn>
                </v-col>
            </v-row>

        </v-container>
    </section>
</template>

<script>
import KyukaiCheckbox from './KyukaiCheckbox.vue';
import moment from "moment";
export default {

    components: {
        KyukaiCheckbox,
    },
    
    data: () => ({

        enabled: true,
        
        
        date: moment.utc().add(9, 'hours'),
        
        stopdateMax: null,
        stopdateMin: null,
        restartdateMax: null,
        restartdateMin: null,

        memberid: '',
        memberidRules: [
        v => !!v || '必須項目です。',
        ],

        name: '',

        mail: '',
        mailRules: [
        v => !!v || '必須項目です。',
        v => /.+@.+\..+/.test(v) || '正しいメールアドレスを入力してください。',
        ],

        birthday: null,
        birthdaymenu: false,
        birthdayRules: [
        v => !!v || '必須項目です。',
        ],

        stopday: null,
        stopdaymenu: false,
        stopdayRules: [
        v => !!v || '必須項目です。',
        ],

        restratday: null,
        restratdaymenu: false,
        restratdayRules: [
        v => !!v || '必須項目です。',
        ],

        biko: '',

    }),

    // インスタンスが作成された後に同期的に呼ばれる
    created: function() {
        
        var day = this.date.date()  //現在日
        var minday = 1;
        var maxday = 10;
        var maxstopdate = new Date(this.date);
        var minstopdate = new Date(this.date);

        if (day >= minday && day <= maxday) {

            minstopdate.setMonth(minstopdate.getMonth() + 1)
            this.stopdateMin = minstopdate
            maxstopdate = new Date(minstopdate)
            maxstopdate.setFullYear(maxstopdate.getFullYear() + 3);
            this.stopdateMax = maxstopdate

            this.restartdateMin = minstopdate
            this.restartdateMax = maxstopdate

        } else {

            minstopdate.setMonth(minstopdate.getMonth() + 2)
            this.stopdateMin = minstopdate
            maxstopdate = new Date(minstopdate)
            maxstopdate.setFullYear(maxstopdate.getFullYear() + 3);
            this.stopdateMax = maxstopdate

            this.restartdateMin = minstopdate
            this.restartdateMax = maxstopdate
        
        }
    },

    watch: {
        birthdaymenu (val) {
            val && setTimeout(() => (
                // 年から選ぶようにする
                this.$refs.birthdaypicker.activePicker = 'YEAR',
                // 選び始めたら初期化
                this.birthdaypickerDate = null
            ))
        },

        stopdaymenu (val) {
            val && setTimeout(() => (
                // 年から選ぶようにする
                this.$refs.stopdaypicker.activePicker = 'YEAR',
                // 選び始めたら初期化
                this.stopdaypickerDate = null
            ))
        },

        restratdaymenu (val) {
            val && setTimeout(() => (
                // 年から選ぶようにする
                this.$refs.restratdaypicker.activePicker = 'YEAR',
                // 選び始めたら初期化
                this.restratdaypickerDate = null
            ))
        },

    },

    methods: {
        birthdaysave (birthday) {
            this.$refs.birthdaymenu.save(birthday)
            // 再入力に備えて、入力が終わったら同期する
            this.birthdaypickerDate = birthday;

            this.getname();
        },
        
        stopdaysave (stopday) {
            this.$refs.stopdaymenu.save(stopday)
            // 再入力に備えて、入力が終わったら同期する
            this.stopdaypickerDate = stopday;

            this.getrestratday();

        },
        
        restratdaysave (restratday) {
            this.$refs.restratdaymenu.save(restratday)
            // 再入力に備えて、入力が終わったら同期する
            this.restratdaypickerDate = restratday;
        },

        getname() {
            
            this.name='';

            if (this.memberid == '' || this.memberid == null || this.birthday == null) {
                return false;
            }

            const postdata = {
                "会員ＣＤ": this.memberid,
                "誕生日": this.birthday
            };

            this.axios.post("https://renaiss-webapi-test.azurewebsites.net/api/RecessQuit/postName",postdata)

            .then(response => {

                if(response.data !== null){
                    this.name = response.data["memberName"];
                }else {
                    //ない処理
                    alert("存在しないアカウントです。")
                    return false;
                }
            })
            .catch(response => console.log(response))
        },

        getrestratday() {
            
            this.enabled = true
            this.restratday = null;

            if (this.stopday == null) {
                return false;
            }

            var startday = new Date(this.stopday + "-01");
            startday.setMonth(startday.getMonth() + 1);
            var endday = new Date(startday);
            endday.setFullYear(endday.getFullYear() + 1);
            this.restartdateMin = startday,
            this.restartdateMax = endday

            this.enabled = false;

        },

        submit() {
            if (this.$refs.form.validate()) {
                
                const putdata = {
                "会員ＣＤ": this.memberid,
                "datetime休会日": this.stopday + "-01",
                "datetime復帰日": this.restratday + "-01"
                };

                this.axios.put("https://renaiss-webapi-test.azurewebsites.net/api/RecessQuit/putDtRecess",putdata)

                .then(response => {
                    
                    alert("休会処理が完了しました。")
                    
            })
            .catch(response => console.log(response))
            
            } else {
                return false;
            }
        },

        onClearClicked() {
            this.enabled = true
            this.restratday = null           
        },

        onClearName() {
            this.name = ''
        },

    },
}
</script>


<style lang="scss" scoped>
.kyukai-main__contents {
    justify-content: center;
    text-align: center;

    &-text {
        word-wrap: break-word;    
    }
}
.button {
    text-align: center;
    margin-top: 10px;
}
</style>